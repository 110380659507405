module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"specyalt-productions","short_name":"specyalT","start_url":"/","background_color":"#000000","theme_color":"#662d91","display":"minimal-ui","icon":"src/images/stp-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2969fa3403c92efdb6fa05a57604540f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"appElement":"#___gatsby","modalProps":{"portalClassName":"modal","closeTimeoutMS":500}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
