// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albums-a-specyal-remedy-js": () => import("./../../../src/pages/albums/a-specyal-remedy.js" /* webpackChunkName: "component---src-pages-albums-a-specyal-remedy-js" */),
  "component---src-pages-albums-cake-ep-js": () => import("./../../../src/pages/albums/cake-ep.js" /* webpackChunkName: "component---src-pages-albums-cake-ep-js" */),
  "component---src-pages-albums-dose-of-distinction-2-js": () => import("./../../../src/pages/albums/dose-of-distinction-2.js" /* webpackChunkName: "component---src-pages-albums-dose-of-distinction-2-js" */),
  "component---src-pages-albums-dose-of-distinction-js": () => import("./../../../src/pages/albums/dose-of-distinction.js" /* webpackChunkName: "component---src-pages-albums-dose-of-distinction-js" */),
  "component---src-pages-albums-foresight-js": () => import("./../../../src/pages/albums/foresight.js" /* webpackChunkName: "component---src-pages-albums-foresight-js" */),
  "component---src-pages-albums-girl-friday-ep-js": () => import("./../../../src/pages/albums/girl-friday-ep.js" /* webpackChunkName: "component---src-pages-albums-girl-friday-ep-js" */),
  "component---src-pages-albums-ontario-in-your-stereo-ep-js": () => import("./../../../src/pages/albums/ontario-in-your-stereo-ep.js" /* webpackChunkName: "component---src-pages-albums-ontario-in-your-stereo-ep-js" */),
  "component---src-pages-albums-she-said-ep-js": () => import("./../../../src/pages/albums/she-said-ep.js" /* webpackChunkName: "component---src-pages-albums-she-said-ep-js" */),
  "component---src-pages-albums-slight-detour-ep-js": () => import("./../../../src/pages/albums/slight-detour-ep.js" /* webpackChunkName: "component---src-pages-albums-slight-detour-ep-js" */),
  "component---src-pages-albums-sweat-it-up-ep-js": () => import("./../../../src/pages/albums/sweat-it-up-ep.js" /* webpackChunkName: "component---src-pages-albums-sweat-it-up-ep-js" */),
  "component---src-pages-albums-that-was-clever-js": () => import("./../../../src/pages/albums/that-was-clever.js" /* webpackChunkName: "component---src-pages-albums-that-was-clever-js" */),
  "component---src-pages-albums-tionne-ep-js": () => import("./../../../src/pages/albums/tionne-ep.js" /* webpackChunkName: "component---src-pages-albums-tionne-ep-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-st-album-credits-js": () => import("./../../../src/pages/st/album-credits.js" /* webpackChunkName: "component---src-pages-st-album-credits-js" */),
  "component---src-pages-st-bio-js": () => import("./../../../src/pages/st/bio.js" /* webpackChunkName: "component---src-pages-st-bio-js" */),
  "component---src-pages-st-contact-js": () => import("./../../../src/pages/st/contact.js" /* webpackChunkName: "component---src-pages-st-contact-js" */),
  "component---src-pages-st-index-js": () => import("./../../../src/pages/st/index.js" /* webpackChunkName: "component---src-pages-st-index-js" */),
  "component---src-pages-st-music-js": () => import("./../../../src/pages/st/music.js" /* webpackChunkName: "component---src-pages-st-music-js" */),
  "component---src-pages-st-photos-js": () => import("./../../../src/pages/st/photos.js" /* webpackChunkName: "component---src-pages-st-photos-js" */),
  "component---src-pages-st-reviews-js": () => import("./../../../src/pages/st/reviews.js" /* webpackChunkName: "component---src-pages-st-reviews-js" */),
  "component---src-pages-st-shop-js": () => import("./../../../src/pages/st/shop.js" /* webpackChunkName: "component---src-pages-st-shop-js" */),
  "component---src-pages-st-shows-js": () => import("./../../../src/pages/st/shows.js" /* webpackChunkName: "component---src-pages-st-shows-js" */),
  "component---src-pages-st-tour-js": () => import("./../../../src/pages/st/tour.js" /* webpackChunkName: "component---src-pages-st-tour-js" */),
  "component---src-pages-st-videos-js": () => import("./../../../src/pages/st/videos.js" /* webpackChunkName: "component---src-pages-st-videos-js" */),
  "component---src-pages-stp-artists-js": () => import("./../../../src/pages/stp/artists.js" /* webpackChunkName: "component---src-pages-stp-artists-js" */),
  "component---src-pages-stp-avenue-kode-studios-js": () => import("./../../../src/pages/stp/avenue-kode-studios.js" /* webpackChunkName: "component---src-pages-stp-avenue-kode-studios-js" */),
  "component---src-pages-stp-components-aks-sidemenu-js": () => import("./../../../src/pages/stp/components/aks_sidemenu.js" /* webpackChunkName: "component---src-pages-stp-components-aks-sidemenu-js" */),
  "component---src-pages-stp-contact-js": () => import("./../../../src/pages/stp/contact.js" /* webpackChunkName: "component---src-pages-stp-contact-js" */),
  "component---src-pages-stp-index-js": () => import("./../../../src/pages/stp/index.js" /* webpackChunkName: "component---src-pages-stp-index-js" */),
  "component---src-pages-stp-services-js": () => import("./../../../src/pages/stp/services.js" /* webpackChunkName: "component---src-pages-stp-services-js" */),
  "component---src-pages-stp-store-js": () => import("./../../../src/pages/stp/store.js" /* webpackChunkName: "component---src-pages-stp-store-js" */),
  "component---src-pages-stp-testimonials-js": () => import("./../../../src/pages/stp/testimonials.js" /* webpackChunkName: "component---src-pages-stp-testimonials-js" */),
  "component---src-pages-stp-videos-js": () => import("./../../../src/pages/stp/videos.js" /* webpackChunkName: "component---src-pages-stp-videos-js" */)
}

