import React from "react"
import loadable from '@loadable/component';

const MusicPlayerST = loadable(() => import('../components/musicst/musicST'))
const MusicPlayerSTP = loadable(() => import('../components/musicstp/musicSTP'))

// export default ({ children, pageContext }) => {
//     if (pageContext.layout === "stp") {
//         return <>  { children }< MusicPlayerSTP /> </>
//     } else if (pageContext.layout === "st") {
//         return <>  { children }< MusicPlayerST /> </>
//     } else {
//         return <> { children } </>
//     }
// }


const layout = ({ children, pageContext }) => {
    if (pageContext.layout === "stp") {
        return <>  { children }< MusicPlayerSTP /> </>
    } else if (pageContext.layout === "st") {
        return <>  { children }< MusicPlayerST /> </>
    } else {
        return <> { children } </>
    }
  }
  
  export default layout
  



// const layout ({ children, pageContext }) => {
//     if (pageContext.layout === "stp") {
//         return <>  { children }< MusicPlayerSTP /> </>
//     } else if (pageContext.layout === "st") {
//         return <>  { children }< MusicPlayerST /> </>
//     } else {
//         return <> { children } </>
//     }
//   };
// export default layout

